import React, { cloneElement } from "react";
import { QuestionCircleFill } from "react-bootstrap-icons";

export default function RATooltip({ children, tooltipElement = <QuestionCircleFill />, className = "" }) {
    return (
        <span className={"tooltip-info " + className}>
            {cloneElement(tooltipElement, { className: "mx-1 tooltip-info" })}
            <span className="tooltiptext">{children}</span>
        </span>
    );
}
