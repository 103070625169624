import {
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineController,
    LineElement,
    LinearScale,
    PointElement,
    SubTitle,
    Title,
    Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import moment from "moment/min/moment-with-locales";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal, Stack, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { Youtube } from "react-bootstrap-icons";
import { Chart } from "react-chartjs-2";
import { useRouteLoaderData } from "react-router-dom";
import ErrorResponse from "./ErrorResponse";
import { findRadioDayparts, formatDaypartText, getRadioName, openYoutube } from "./HelperFunctions";
import { useFetchData, useRadioName, useResolvedRadios } from "./HelperHooks";
import LoadingIndicator from "./LoadingIndicator";
import RATooltip from "./RATooltip";
import ScreenshotButton from "./ScreenshotButton";

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    ChartDataLabels,
    Title,
    SubTitle
);

export default function TrendOverTime({ show, onHide, radioId, itemId, dataType, daypartId = 1, listenerGroup = 0, demoId = 0 }) {
    const { dayparts } = useRouteLoaderData("root");

    const [loading, errorCode, data, fetchData] = useFetchData();

    const [grouping, setGrouping] = useState("month");
    const [monthsLimit, setMonthsLimit] = useState(8);
    const [daypartName, setDaypartName] = useState("");
    const radioName = useRadioName(radioId);

    const resolvedRadios = useResolvedRadios(true);

    const chartRef = useRef(null);
    const containerRef = useRef(null);

    const backgroundPlugin = {
        id: "customCanvasBackgroundColor",
        beforeDraw: (chart, args, options) => {
            const { ctx } = chart;
            ctx.save();
            ctx.globalCompositeOperation = "destination-over";
            ctx.fillStyle = options.color || "#99ffff";
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        },
    };

    useEffect(() => {
        async function loadData() {
            const url = `music_test/${radioId}/trend_over_time/${itemId}?data_type=${dataType}&daypart_id=${daypartId}&listener_group=${listenerGroup}&demo_id=${demoId}&months_limit=${monthsLimit}`;
            await fetchData(url);
        }
        if (radioId && itemId && show) loadData();
    }, [show, dataType, daypartId, demoId, itemId, listenerGroup, monthsLimit, radioId, fetchData]);

    useEffect(() => {
        if (data && data.data_week.length < 8) setGrouping("week");
    }, [data]);

    useEffect(() => {
        async function getDaypartName() {
            setDaypartName(formatDaypartText(findRadioDayparts((await dayparts).data, radioId, dataType).find(x => x.daypart_id === daypartId)));
        }
        if (daypartId) getDaypartName();
    }, [dataType, daypartId, dayparts, radioId]);

    const chartOptions = {
        aspectRatio: window.innerHeight - 100 < window.innerWidth || window.innerWidth > 800 ? 2 : 1,
        borderRadius: 5,
        plugins: {
            title: {
                display: true,
                text:
                    (data?.song?.artist + data?.song?.title).length < 40
                        ? `${data?.song?.artist} - ${data?.song?.title}`
                        : [data?.song?.artist, data?.song?.title],
                font: { size: 18 },
                padding: { bottom: 5 },
            },
            subtitle: {
                display: true,
                text: `${radioName} | ${daypartName} ${monthsLimit ? `| Latest ${monthsLimit} months` : ""}`,
                padding: { bottom: 5 },
            },
            tooltip: {
                footerFont: { weight: "normal" },
                // Spins Breakdown

                // callbacks: {
                //     footer: items => {
                //         if (!data) return;
                //         const selectedGroupingData = grouping === "week" ? data?.data_week : data?.data_month;
                //         const spinsBreakdown = selectedGroupingData[items[0].dataIndex].market_radio_spins;
                //         if (!spinsBreakdown?.length) return;
                //         const spinsBreakdownStr = ["MARKET SPINS BY STATION:"].concat(
                //             spinsBreakdown
                //                 .sort((a, b) => b.spins - a.spins)
                //                 .map(x => {
                //                     const name = getRadioName(x.radio_id, resolvedRadios);
                //                     return name === "Unknown" ? null : `${name}: ${x.spins}`;
                //                 })
                //                 .filter(x => x)
                //         );
                //         return spinsBreakdownStr;
                //     },
                // },
            },
            datalabels: {
                labels: {
                    index: {
                        padding: 0,
                        color: "black",
                        font: {
                            size: 12,
                            weight: "bold",
                        },
                    },
                },
            },
            legend: {
                position: "bottom",
                labels: { usePointStyle: true },
            },
            customCanvasBackgroundColor: { color: "white" },
        },
        scales: {
            scores: {
                type: "linear",
                max: 10,
                beginAtZero: true,
                grid: { display: false },
            },
            spins: {
                position: "right",
                type: "linear",
                beginAtZero: true,
                grid: { display: false },
            },
            x: {
                ticks: { maxRotation: 90 },
                grid: { display: false },
            },
        },
        interaction: { mode: "index" },
    };

    const chartData = useMemo(() => {
        if (data) {
            const selectedGroupingData = grouping === "week" ? data.data_week : data.data_month;
            return {
                labels: selectedGroupingData.map(x => moment(x.basis_week).format("L")),
                datasets: [
                    {
                        type: "line",
                        label: "Song Score",
                        borderColor: "#3264B4",
                        pointBackgroundColor: "#3264B4",
                        borderWidth: 4,
                        pointBorderWidth: 4,
                        pointHitRadius: 6,
                        hoverRadius: 6,
                        data: selectedGroupingData.map(x => x.song_score.toFixed(1)),
                        yAxisID: "scores",
                        datalabels: {
                            labels: {
                                index: {
                                    align: "end",
                                    anchor: "end",
                                },
                            },
                        },
                    },
                    {
                        type: "line",
                        label: "Burn",
                        borderColor: "#A52A2A",
                        pointBackgroundColor: "#A52A2A",
                        borderWidth: 4,
                        pointBorderWidth: 4,
                        pointHitRadius: 6,
                        hoverRadius: 6,
                        fill: false,
                        data: selectedGroupingData.map(x => x.burn?.toFixed(1)),
                        yAxisID: "scores",
                        datalabels: {
                            labels: {
                                index: {
                                    align: "end",
                                    anchor: "end",
                                },
                            },
                        },
                    },
                    {
                        type: "bar",
                        label: "Spins",
                        backgroundColor: "#FEC834",
                        data: selectedGroupingData.map(x => x.spins),
                        borderColor: "white",
                        yAxisID: "spins",
                        pointStyle: "rectRounded",
                    },
                    {
                        type: "bar",
                        label: "Market Spins",
                        backgroundColor: "#88CEAB",
                        data: selectedGroupingData.map(x => x.market_spins),
                        yAxisID: "spins",
                        pointStyle: "rectRounded",
                    },
                ],
            };
        }
    }, [data, grouping]);

    return (
        <Modal show={show} onHide={onHide} size="xl" centered className="trend-over-time-modal">
            <Modal.Header closeButton className=" py-1 py-xl-3">
                <Modal.Title className=" text-uppercase">Trend Over Time</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column pt-1 row-gap-2">
                <Stack direction="horizontal" gap={2} className=" flex-wrap">
                    <span className="d-none d-md-inline-block fw-bold text-uppercase fs-5">Group By:</span>
                    <ToggleButtonGroup type="radio" name="tot-grouping" className="ra-switcher" onChange={val => setGrouping(val)} value={grouping}>
                        <ToggleButton id={`tot-week`} value={"week"} variant="ra-dark-blue-outline" className=" align-content-center">
                            Week
                        </ToggleButton>
                        <ToggleButton id={`tot-month`} value={"month"} variant="ra-dark-blue-outline" className="d-flex align-items-center column-gap-1">
                            Month
                            <RATooltip size={18}>Monthly data includes all weeks starting in named month.</RATooltip>
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <span className="d-none d-md-inline-block fw-bold text-uppercase fs-5">Limit:</span>
                    <ToggleButtonGroup type="radio" name="music-test-trend-limit" className="ra-switcher" onChange={setMonthsLimit} value={monthsLimit}>
                        <ToggleButton id={`music-test-trend-limit-0`} value={0} variant="ra-dark-blue-outline" className=" align-content-center">
                            Full
                        </ToggleButton>
                        <ToggleButton id={`music-test-trend-limit-8`} value={8} variant="ra-dark-blue-outline" className=" align-content-center text-nowrap">
                            8 Months
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Button
                        variant={"contained"}
                        onClick={() => openYoutube(data?.song)}
                        disabled={!data}
                        className="p-0 border-0 btn-download ms-auto d-flex align-items-center column-gap-2">
                        <Youtube width={35} height={35} />
                        <span className="d-none d-md-inline-block">Youtube</span>
                    </Button>
                    <ScreenshotButton
                        container={containerRef}
                        fileName={`Trend_${radioName}_${data?.song?.artist}_${data?.song?.title}`.replaceAll(" ", "_")}
                    />
                </Stack>
                <div ref={containerRef}>
                    {loading ? (
                        <LoadingIndicator width={75} />
                    ) : errorCode ? (
                        <ErrorResponse errorCode={errorCode} />
                    ) : (
                        data && <Chart ref={chartRef} data={chartData} options={chartOptions} plugins={[backgroundPlugin]} />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export function useMusicTestTrendModal() {
    const [trendSong, setTrendSong] = useState(null);

    const MusicTestTrendModalComponent = ({ radioId, dataType, daypartId }) => (
        <TrendOverTime
            show={!!trendSong}
            onHide={() => setTrendSong(null)}
            radioId={radioId}
            itemId={trendSong?.item_id}
            dataType={dataType}
            daypartId={daypartId}
        />
    );

    return { setTrendSong, MusicTestTrendModalComponent };
}
