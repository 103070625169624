import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../auth/Auth";
import { Container, Card, Button, Table, Row, Col, Spinner } from "react-bootstrap";
import CreationOFABeast from "../../assets/blogs/CreationOfABeast.jpg";
import CrunchTime from "../../assets/blogs/CrunchTime.jpg";
import TopicTestingPng from "../../assets/blogs/TopicTesting.jpg";
import { Await, useRouteLoaderData } from "react-router-dom";
import { Suspense } from "react";
import moment from "moment/min/moment-with-locales";
import { TruncateText } from "../../components/HelperFunctions";
import { FiletypePdf, PersonFill, Clock } from "react-bootstrap-icons";

const Dashboard = () => {
    const { userInfo, radios } = useRouteLoaderData("root");
    const maxLength = window.innerWidth < 1550 ? 30 : 55;

    return (
        <Container fluid className={"p-4"}>
            <Row>
                <Col className="bg-white p-4">
                    <Row>
                        <h2>
                            Welcome{" "}
                            <Suspense>
                                <Await resolve={userInfo}>{resolvedUser => <span>{resolvedUser.data.greeting_name}</span>}</Await>
                            </Suspense>
                        </h2>
                    </Row>
                    <Row className="row-cols-1 row-cols-xl-2">
                        <Col xl={4} className="me-4">
                            <h5 className="fw-bold mt-4">Your latest data</h5>
                            <Suspense>
                                <Await resolve={radios}>
                                    {resolvedRadios => (
                                        <Table hover>
                                            <thead>
                                                <tr>
                                                    <th className="col-1">Radio name</th>
                                                    <th className="col-1">Music Test</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {resolvedRadios.data.map(x => (
                                                    <LatestCalculatedDataRow key={x.radio_id} radio={x} />
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}
                                </Await>
                            </Suspense>
                        </Col>
                        <Col>
                            <h5 className="fw-bold mt-4 mb-4">To get you going</h5>
                            <p>Do you need some help to get started? We got some documents for you!</p>
                            <ul className="ms-3 mt-1 ">
                                <li>
                                    RadioAnalyzer Definition{" "}
                                    <a href="https://radioanalyzer.com/pdf/Definitions.pdf" target=" blank">
                                        <FiletypePdf className="fs-3 c-red pe-auto" />
                                    </a>
                                </li>
                                <li>
                                    Music Score and Burn{" "}
                                    <a href="https://radioanalyzer.com/pdf/Music_Score_and_Burn.pdf" target="_blank" rel="noreferrer">
                                        {" "}
                                        <FiletypePdf className="fs-3 c-red pe-auto" />
                                    </a>
                                </li>
                                <li>
                                    How to plan your playlist{" "}
                                    <a href="https://radioanalyzer.com/pdf/how_to_plan_your_playlist.pdf" target=" blank">
                                        <FiletypePdf className="fs-3 c-red pe-auto" />
                                    </a>
                                </li>
                            </ul>
                            <span>
                                If you have any technical questions or you would like some help, please reach us at{" "}
                                <a className="text-dark fw-bold " href="mailto:support@radioanalyzer.com" target=" blank">
                                    support@radioanalyzer.com
                                </a>
                            </span>
                        </Col>
                    </Row>

                    <h2 className="fs-4vh py-4  text-center text-uppercase">What's New?</h2>
                    <Row className="row-cols-1 row-cols-xl-3 text-center">
                        <Col>
                            <Card className="my-4 my-lg-0 w-100">
                                <Card.Img variant="top" src={TopicTestingPng} />
                                <Card.Body>
                                    <Card.Title>
                                        <TruncateText text="Coming soon: Topic Testing – a Beast of a Weapon" maxLength={maxLength} />
                                    </Card.Title>
                                    <Card.Text className="text-body-secondary py-2">
                                        <PersonFill /> Mikkel Ottesen Lønstrup <br />
                                        <Clock /> November 3, 2023
                                    </Card.Text>
                                    <Button
                                        onClick={() => window.open("https://radioanalyzer.com/coming-soon-content-testing/", "_blank")}
                                        variant="primary btn-ra-dark-blue">
                                        Read full article
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="my-4 my-lg-0 w-100">
                                <Card.Img variant="top" src={CrunchTime} />
                                <Card.Body>
                                    <Card.Title>
                                        <TruncateText text="Crunch time for Radio. Let’s find the path together in Munich!" maxLength={maxLength} />
                                    </Card.Title>
                                    <Card.Text className="text-body-secondary py-2">
                                        <PersonFill /> Mikkel Ottesen Lønstrup <br />
                                        <Clock /> February 16, 2024
                                    </Card.Text>
                                    <Button
                                        onClick={() => window.open("https://radioanalyzer.com/radiodays2024/", "_blank")}
                                        variant="primary btn-ra-dark-blue">
                                        Read full article
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="my-4 my-lg-0 w-100">
                                <Card.Img variant="top" src={CreationOFABeast} />
                                <Card.Body>
                                    <Card.Title>
                                        <TruncateText text="The Creation of a Beast" maxLength={maxLength} />
                                    </Card.Title>
                                    <Card.Text className="text-body-secondary py-2">
                                        <PersonFill /> Mikkel Ottesen Lønstrup <br />
                                        <Clock /> April 22, 2024
                                    </Card.Text>
                                    <Button
                                        onClick={() => window.open("https://radioanalyzer.com/content-testing/", "_blank")}
                                        variant="primary btn-ra-dark-blue">
                                        Read full article
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};
function LatestCalculatedDataRow({ radio }) {
    const [latestAvailableDate, setLatestAvailableDate] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function getLatestAvailableDate() {
            setLoading(true);
            const response = await axiosInstance.get("/radios/" + radio.radio_id + "/latest_calculated_date?data_type=1&name=song_deltas");
            setLatestAvailableDate(response.data);
            setLoading(false);
        }
        getLatestAvailableDate();
    }, [radio]);

    return (
        <tr>
            <td>{radio.radio_name}</td>
            <td>
                {loading ? (
                    <>
                        <span>Loading</span> <Spinner size="sm" />
                    </>
                ) : (
                    moment(latestAvailableDate).format("L")
                )}
            </td>
        </tr>
    );
}

export default Dashboard;
