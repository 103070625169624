import React, { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const AuthContext = React.createContext(null);

export let axiosInstance = axios.create({
    baseURL: "https://api.radioanalyzerserver.dk",
    headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
    },
});
export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [token, setToken] = useState(localStorage.getItem("access_token"));

    const handleLogin = async formData => {
        try {
            const response = await axios.post("https://api.radioanalyzerserver.dk/token", formData);
            const access_token = response.data["access_token"];
            const refresh_token = response.data["refresh_token"];
            //set JWT token to local
            localStorage.setItem("access_token", access_token);
            localStorage.setItem("refresh_token", refresh_token);
            setToken(localStorage.getItem("access_token"));
            axiosInstance = axios.create({
                baseURL: "https://api.radioanalyzerserver.dk",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    "Content-Type": "application/json",
                },
            });
            //validate user access
            try {
                let mtt_validate = axiosInstance.get("/validate_mtt");
            } catch (err) {
                return "Oops! Looks like you are logging into the wrong tool. Please try at RadioAnalyzer Pro.";
            }
            const origin = location.state?.from?.pathname || "/";
            navigate(origin);
        } catch (err) {
            handleLogout();
            return "Woops! Looks like you posted the wrong credentials.";
        }
    };

    const handleLogout = () => {
        setToken(null);
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
    };

    const value = useMemo(
        () => ({
            token,
            onLogin: handleLogin,
            onLogout: handleLogout,
        }),
        [token]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return React.useContext(AuthContext);
};
