import React, { Suspense, useState, useMemo, useEffect } from "react";
import { Await, useRouteLoaderData } from "react-router-dom";
import { Button, Col, Table, Container, Form, Row, ToggleButton, ToggleButtonGroup, Stack, InputGroup } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { axiosInstance } from "../../auth/Auth";
import LoadingIndicator from "../../components/LoadingIndicator";
import { CaretDownFill, CaretUpFill, BarChartLineFill, Search } from "react-bootstrap-icons";
import { get_song_score_color, get_category_move_color } from "../../components/HelperFunctions";
import useSortableData from "../../components/useSortableData";
import TrendOverTime from "../../components/TrendOverTime";
import ExportData from "../../components/ExportData";
import moment from "moment/min/moment-with-locales";
import RATooltip from "../../components/RATooltip";
import EmptyTableRow from "../../components/EmptyTableRow";
import ErrorResponse from "../../components/ErrorResponse";

const perspectiveOptions = [
    { value: 1, label: "All" },
    { value: 2, label: "Playlist" },
    { value: 3, label: "Backpool" },
    { value: 9, label: "Custom" },
];
const perspectiveCategories = [
    {
        perspectiveId: 1,
        categories: ["New", "Power", "Recurrent", "2020s", "2010s", "2000s", "1990s", "1980s", "1970s", "older"],
    },
    { perspectiveId: 2, categories: ["New", "Power"] },
    {
        perspectiveId: 3,
        categories: ["Recurrent", "2020s", "2010s", "2000s", "1990s", "1980s", "1970s", "older"],
    },
];

const categorySortOrder = [
    "N1",
    "N2",
    "N3",
    "P1",
    "P2",
    "P3",
    "R1",
    "R2",
    "R3",
    "21",
    "22",
    "23",
    "11",
    "12",
    "13",
    "01",
    "02",
    "03",
    "91",
    "92",
    "93",
    "81",
    "82",
    "83",
    "71",
    "72",
    "73",
    "61",
    "62",
    "63",
    "B1",
    "B2",
    "B3",
    "U",
];

const PlaylistAutopilot = () => {
    const [loading, setLoading] = useState(false);
    const { radios } = useRouteLoaderData("root");
    const [errorCode, setErrorCode] = useState(null);
    const [station, setStation] = useState([]);
    const [defaultStation, setDefaultStation] = useState(
        localStorage.getItem("PlaylistAutopilotStation") ? JSON.parse(localStorage.getItem("PlaylistAutopilotStation")) : null
    );
    const [demos, setDemos] = useState(1);
    const [showCategories, setShowCategories] = useState(
        localStorage.getItem("PlaylistAutopilotShowCategories") ? JSON.parse(localStorage.getItem("PlaylistAutopilotShowCategories")) : 2
    );
    const [perspectiveOption, setPerspectiveOption] = useState("");
    const [playlistAutopilotData, setPlaylistAutopilotData] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState(
        localStorage.getItem("PlaylistAutopilotCategories")
            ? JSON.parse(localStorage.getItem("PlaylistAutopilotCategories"))
            : perspectiveCategories[0].categories
    );

    const [arePlapStations, setArePlapStations] = useState("false");
    const [searchValue, setSearchValue] = useState("");

    const playlistAutopilotDataMemo = useMemo(() => {
        if (playlistAutopilotData) {
            let filteredData = playlistAutopilotData.data;
            if (Number(showCategories) === 2) {
                filteredData = filteredData?.filter(item => item.song_category !== item.song_category_move);
            }
            filteredData = filteredData.filter(x => selectedCategories.some(category => x.song_category_name.includes(category)));
            if (searchValue) {
                filteredData = filteredData.filter(
                    x =>
                        x.song.artist.toLowerCase().includes(searchValue.toLowerCase().trim()) ||
                        x.song.title.toLowerCase().includes(searchValue.toLowerCase().trim()) ||
                        x.song.release_year?.toString().includes(searchValue.trim())
                );
            }
            return filteredData;
        }
    }, [playlistAutopilotData, searchValue, selectedCategories, showCategories]);

    useEffect(() => {
        localStorage.setItem("PlaylistAutopilotShowCategories", JSON.stringify(showCategories));
    }, [showCategories]);

    useEffect(() => {
        localStorage.setItem("PlaylistAutopilotCategories", JSON.stringify(selectedCategories));
    }, [selectedCategories]);

    useEffect(() => {
        const cats = perspectiveCategories.find(x => x.perspectiveId === perspectiveOption)?.categories;
        if (cats) setSelectedCategories(cats);
    }, [perspectiveOption]);

    useEffect(() => {
        if (selectedCategories.length) {
            const perspectiveId = perspectiveCategories.find(x => JSON.stringify(x.categories) === JSON.stringify(selectedCategories))?.perspectiveId ?? 9;
            setPerspectiveOption(perspectiveId);
        }
    }, [selectedCategories]);

    const { sortedItems, requestSort, sortConfig } = useSortableData(playlistAutopilotDataMemo, { key: "spins", direction: "descending" });

    function handleStationChange(value) {
        setDefaultStation(null);
        setStation(value);
    }

    async function handleFormSubmit(event) {
        setLoading(true);
        event.preventDefault();
        if (station.length) localStorage.setItem("PlaylistAutopilotStation", JSON.stringify(station[0].radio_id));
        try {
            const radioId = defaultStation ?? station[0].radio_id;
            const response = await axiosInstance.get(`/playlist_autopilot/${radioId}?data_type=1`);
            setErrorCode(null);
            setPlaylistAutopilotData(response.data);
        } catch (error) {
            setPlaylistAutopilotData(null);
            setErrorCode(error?.response?.status ?? 500);
        }
        setLoading(false);
    }

    const csvHeaders = ["Category Name", "Category Move", "Artist", "Title", "Spins", "Score Week", "Score Month", "Burn", "Release Year"];
    const csvData =
        sortedItems?.map(x => [
            x.song_category_name,
            x.song_category_move_name,
            x.song.artist,
            x.song.title,
            x.spins,
            x.song_score,
            x.song_score_month,
            x.burn,
            x.song.release_year,
        ]) ?? [];
    return (
        <Container fluid className="p-4">
            <Row>
                <Col className="bg-white p-4 h-100">
                    <h1 className="text-uppercase">Playlist Autopilot</h1>

                    {arePlapStations ? (
                        <p>
                            Contact your RA rep or{" "}
                            <a
                                className="text-dark fw-bold"
                                href="mailto:training@radioanalyzer.com?subject=Upgrade%20to%20Playlist%20Autopilot"
                                target="blank">
                                Click Here
                            </a>{" "}
                            to upgrade...
                        </p>
                    ) : (
                        ""
                    )}

                    <Form onSubmit={handleFormSubmit} className="row">
                        <Col xs={12} xl>
                            <Row>
                                <Suspense fallback={<span>Loading...</span>}>
                                    <Await resolve={radios}>
                                        {resolvedRadios => {
                                            setArePlapStations(resolvedRadios.data.filter(x => x.stream_settings.playlist_autopilot).length === 0);
                                            return (
                                                <Form.Group controlId="song-search-station" as={Col} className="mb-3 mb-xl-0 col-12 col-xl-3 ">
                                                    <Form.Label>Station</Form.Label>
                                                    <Typeahead
                                                        id="song-search-station-selection"
                                                        options={resolvedRadios.data.filter(x => x.stream_settings.playlist_autopilot)}
                                                        labelKey={"radio_name"}
                                                        highlightOnlyResult
                                                        placeholder="Select station..."
                                                        clearButton={true}
                                                        onChange={handleStationChange}
                                                        selected={defaultStation ? resolvedRadios.data.filter(x => defaultStation === x.radio_id) : station}
                                                        disabled={arePlapStations}
                                                    />
                                                </Form.Group>
                                            );
                                        }}
                                    </Await>
                                </Suspense>
                                <Form.Group controlId="playlist-autopilot-perspective" as={Col} className="mb-3 mb-xl-0 col-12 col-xl-2">
                                    <Form.Label>Perspective</Form.Label>
                                    <Form.Select
                                        value={perspectiveOption}
                                        disabled={arePlapStations}
                                        onChange={e => setPerspectiveOption(Number(e.target.value))}>
                                        {perspectiveOptions.map(x => (
                                            <option key={x.value} value={x.value}>
                                                {x.label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} className="d-flex flex-column mb-3 mb-xl-0 col-12 col-xl-3">
                                    <Form.Label>Data type</Form.Label>
                                    <ToggleButtonGroup type={"radio"} name="demos" size={"md"} value={demos} className=" ra-switcher">
                                        <ToggleButton id={`plap-data-type-stream`} variant={"contained"} disabled={true} value={1} onChange={() => setDemos(1)}>
                                            Stream
                                        </ToggleButton>
                                        <ToggleButton id={`plap-data-type-ppm`} variant={"contained"} value={2} disabled={true} onChange={() => setDemos(2)}>
                                            Ratings
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Form.Group>
                                <Form.Group as={Col} className="mb-3 mb-xl-0 mt-xl-3 col-12 col-xl-11 order-xl-last">
                                    <Form.Label>
                                        Select Categories
                                        <RATooltip>
                                            By picking a particular category, you'll filter the results to show only items within your chosen category.
                                        </RATooltip>
                                    </Form.Label>
                                    <Typeahead
                                        id="category-selection-dropdown"
                                        options={perspectiveCategories[0].categories}
                                        multiple
                                        clearButton
                                        selected={selectedCategories}
                                        disabled={arePlapStations}
                                        onChange={setSelectedCategories}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} className="d-flex flex-column mb-3 mb-xl-0 col-12 col-xl-3">
                                    <Form.Label>
                                        Show Categories
                                        <RATooltip>
                                            Selecting 'Changes' will only display results with song categories that are suggested to be moved. Choosing 'All'
                                            will show categories that should be changed and those that should remain the same.
                                        </RATooltip>
                                    </Form.Label>
                                    <ToggleButtonGroup
                                        type="radio"
                                        name="categories"
                                        size="md"
                                        value={showCategories}
                                        onChange={setShowCategories}
                                        className="ra-switcher">
                                        <ToggleButton id="show_category" variant="outline-secondary" disabled={arePlapStations} value={2}>
                                            Changes
                                        </ToggleButton>
                                        <ToggleButton id="no_show_category" variant="outline-secondary" disabled={arePlapStations} value={1}>
                                            All
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Form.Group>
                            </Row>
                        </Col>
                        <Col xs={12} xl={"auto"} className="text-center text-xl-end align-self-end">
                            <Button
                                type="submit"
                                className="btn-ra-yellow text-uppercase px-5 p-1"
                                disabled={(!defaultStation && !station.length) || arePlapStations}>
                                Analyze
                            </Button>
                        </Col>
                    </Form>
                </Col>
            </Row>
            <hr className="m-2"></hr>
            <Row>
                <Col className="bg-white p-4">
                    {!loading && !errorCode && !playlistAutopilotData && (
                        <Container fluid className="p-4">
                            <Row>
                                <Col xs={12} md={12} lg={8}>
                                    <h6 className="fw-bold list-unstyled">What can you do with Playlist Autopilot?</h6>
                                    <ul>
                                        <li>Automate your playlist changes</li>
                                        <li>Category moves are updated weekly on new song data</li>
                                        <li>Automated export to your music planning system coming soon!</li>
                                    </ul>
                                    <h6 className="fw-bold">Nice to know</h6>
                                    <ul>
                                        <li>Choose between your playlist, your back pool, or any category(ies) of your choosing </li>
                                        <li>Click on trend to see the recent song development </li>
                                        <li>
                                            Check out our{" "}
                                            <a className="text-dark fw-bold " href="https://radioanalyzer.com/pdf/Music_Score_and_Burn.pdf" target=" blank">
                                                “Song Score and Burn”
                                            </a>{" "}
                                            primer for a more detailed explanation of what the numbers mean
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled">
                                        <li className="fw-bold list-unstyled">Related reading:</li>
                                        <li className="list-unstyled ">
                                            <a
                                                className="text-dark text-decoration-none fst-italic"
                                                href="https://radioanalyzer.com/playlist-autopilot/"
                                                target=" blank">
                                                Introducing Playlist Autopilot: The Future of Music Curation
                                            </a>
                                        </li>
                                        <li className="list-unstyled ">
                                            <a
                                                className="text-dark text-decoration-none fst-italic"
                                                href="https://radioanalyzer.com/playlist-autopilot-update/"
                                                target=" blank">
                                                Playlist Autopilot: We Are Flicking the Switch to “On”
                                            </a>
                                        </li>
                                    </ul>
                                    <p>
                                        If you need further assistance in using Playlist Autopilot, please contact{" "}
                                        <a
                                            className="text-dark fw-bold "
                                            href="mailto:training@radioanalyzer.com?subject=Training%20for%20Playlist%20Autopilot"
                                            target=" blank">
                                            training@radioanalyzer.com
                                        </a>
                                    </p>
                                </Col>
                                <Col xs={12} md={12} xl={4}>
                                    <div className="iframe-styling">
                                        <iframe
                                            width="100%"
                                            height="100%"
                                            src="https://www.youtube.com/embed/XNhTC6nnYSw?si=kqyWYKJJJmupIAW1"
                                            title="How to use Playlist Autopilot"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowFullScreen></iframe>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    )}
                    {!loading && errorCode && <ErrorResponse errorCode={errorCode} />}
                    {loading ? (
                        <span colSpan={12}>
                            <LoadingIndicator />
                        </span>
                    ) : (
                        playlistAutopilotData && (
                            <>
                                <Stack direction="horizontal" gap={2} className="mb-3 flex-wrap">
                                    <Stack direction="horizontal" gap={1}>
                                        <span>Station:</span>
                                        {station.length ? (
                                            <span className=" fw-bold">{station[0].radio_name}</span>
                                        ) : (
                                            <Suspense>
                                                <Await resolve={radios}>
                                                    {resolvedRadios => (
                                                        <span className="fw-bold">
                                                            {resolvedRadios.data.find(x => x.radio_id === defaultStation)?.radio_name}
                                                        </span>
                                                    )}
                                                </Await>
                                            </Suspense>
                                        )}
                                    </Stack>
                                    <div className="vr d-none d-xl-inline-block"></div>
                                    <Stack direction="horizontal" gap={1}>
                                        <span>Time period:</span>
                                        <span className="fw-bold">
                                            {moment(playlistAutopilotData.basisWeek).format("L")} -{" "}
                                            {moment(playlistAutopilotData.basisWeek).endOf("isoWeek").format("L")}
                                        </span>
                                    </Stack>
                                    <div className="vr d-none d-xl-inline-block"></div>
                                    <Stack direction="horizontal" gap={1}>
                                        <span>Category:</span>
                                        <span className="fw-bold">{perspectiveOptions.find(x => x.value === perspectiveOption)?.label}</span>
                                    </Stack>
                                </Stack>

                                <Stack direction="horizontal" gap={1} className=" mb-2 flex-wrap-reverse">
                                    <Col xs={12} xl={5}>
                                        <InputGroup>
                                            <InputGroup.Text className="input-icon">
                                                <Search />
                                            </InputGroup.Text>
                                            <Form.Control
                                                value={searchValue}
                                                onChange={e => setSearchValue(e.target.value)}
                                                placeholder="search by artist, title or release date"
                                            />
                                        </InputGroup>
                                    </Col>
                                    <div className=" ms-xl-auto">
                                        <span className="me-2">Download:</span>
                                        <ExportData
                                            data={csvData}
                                            headers={csvHeaders}
                                            filename={`Playlist changes results on ${station[0]?.radio_name} for demographic Online All for week ${moment(
                                                playlistAutopilotData.basisWeek
                                            ).format("L")}`}
                                        />
                                    </div>
                                </Stack>

                                <Table size="sm" hover responsive className="plap-table">
                                    <thead>
                                        <tr>
                                            <th className="col-1" onClick={() => requestSort("song_category", "ascending", categorySortOrder)}>
                                                <Stack direction="horizontal" className="justify-content-center text-center">
                                                    <span className=" d-flex flex-wrap-reverse justify-content-center offset-1 align-items-center">
                                                        Category Name
                                                        <RATooltip>
                                                            <p>Songs are sorted based on age, market exposure and spins on your own station:</p>
                                                            <p>
                                                                <b>New</b> – New Music in your market, usually up to about 1,5-2 months old.
                                                            </p>
                                                            <p>
                                                                <b>Power</b> – your best current songs.
                                                            </p>
                                                            <p>
                                                                <b>Recurrent</b> – songs you play from the past few years.
                                                            </p>
                                                            <p>
                                                                <b>2010s to 1960s</b> – everything else sorted by decades.
                                                            </p>
                                                            <p className="mb-0">
                                                                <b>Levels 1</b> is your most played songs in each category and <b>Level 2</b> are the songs you
                                                                play less.
                                                            </p>
                                                        </RATooltip>
                                                    </span>
                                                    <span className="col-1">
                                                        {sortConfig?.key === "song_category" &&
                                                            (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                    </span>
                                                </Stack>
                                            </th>
                                            <th className="col-1" onClick={() => requestSort("song_category_move", "ascending", categorySortOrder)}>
                                                <Stack direction="horizontal" className="justify-content-center text-center">
                                                    <span className=" d-flex flex-wrap-reverse justify-content-center offset-1 align-items-center">
                                                        Category Move
                                                        <RATooltip>
                                                            Move suggestions are based on song performance, trends and age. Songs moved to level 3 should be
                                                            rested for a while or removed.
                                                        </RATooltip>
                                                    </span>
                                                    <span className="col-1">
                                                        {sortConfig?.key === "song_category_move" &&
                                                            (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                    </span>
                                                </Stack>
                                            </th>
                                            <th className="col-2" onClick={() => requestSort("song.artist")}>
                                                <Stack direction="horizontal">
                                                    <span>Artist</span>
                                                    <span className="col-1">
                                                        {sortConfig?.key === "song.artist" &&
                                                            (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                    </span>
                                                </Stack>
                                            </th>
                                            <th className="col-2" onClick={() => requestSort("song.title")}>
                                                <Stack direction="horizontal">
                                                    <span>Title</span>
                                                    <span className="col-1">
                                                        {sortConfig?.key === "song.title" &&
                                                            (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                    </span>
                                                </Stack>
                                            </th>
                                            <th className="col-1" onClick={() => requestSort("spins")}>
                                                <Stack direction="horizontal" className="justify-content-center">
                                                    <span className="offset-1">Spins</span>
                                                    <span className="col-1">
                                                        {sortConfig?.key === "spins" &&
                                                            (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                    </span>
                                                </Stack>
                                            </th>
                                            <th className="col-1" onClick={() => requestSort("total_spins")}>
                                                <Stack direction="horizontal" className="justify-content-center text-center">
                                                    <span className="offset-1">Total Spins</span>
                                                    <span className="col-1">
                                                        {sortConfig?.key === "total_spins" &&
                                                            (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                    </span>
                                                </Stack>
                                            </th>
                                            <th className="col-1" onClick={() => requestSort("song_score")}>
                                                <Stack direction="horizontal" className="justify-content-center text-center">
                                                    <span className=" d-flex flex-wrap-reverse justify-content-center offset-1 align-items-center">
                                                        Score week
                                                        <RATooltip>
                                                            <p>
                                                                Song Score ranks listener reactions to your music. Here is one suggested implementation filter:
                                                            </p>
                                                            <p>
                                                                <span className="color-square bg-score-worst"></span> 1-3: Rest or drop (depending on trend and
                                                                sound)
                                                            </p>
                                                            <p>
                                                                <span className="color-square bg-score-worst"></span> 3-4.5: Fewer spins or drop
                                                            </p>
                                                            <p>
                                                                <span className="color-square bg-score-worse"></span> 4.5-5: Stay on a good trend or a sound
                                                                variety pick
                                                            </p>
                                                            <p>
                                                                <span className="color-square bg-score-middle"></span> 5-6: Stay (move up new songs at ca 5.5)
                                                            </p>
                                                            <p>
                                                                <span className="color-square bg-score-better"></span> 6-7: Slightly more spins on a steady
                                                                trend/plateau
                                                            </p>
                                                            <p className="mb-0">
                                                                <span className="color-square bg-score-best"></span> 7-10: Significantly more spins on a steady
                                                                trend/plateau
                                                            </p>
                                                        </RATooltip>
                                                    </span>
                                                    <span className="col-1">
                                                        {sortConfig?.key === "song_score" &&
                                                            (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                    </span>
                                                </Stack>
                                            </th>
                                            <th className="col-1" onClick={() => requestSort("song_score_month")}>
                                                <Stack direction="horizontal" className="justify-content-center text-center">
                                                    <span className=" d-flex flex-wrap-reverse justify-content-center offset-1 align-items-center">
                                                        Score month
                                                        <RATooltip>
                                                            <p>
                                                                Song Score ranks listener reactions to your music. Here is one suggested implementation filter:
                                                            </p>
                                                            <p>
                                                                <span className="color-square bg-score-worst"></span> 1-3: Rest or drop (depending on trend and
                                                                sound)
                                                            </p>
                                                            <p>
                                                                <span className="color-square bg-score-worst"></span> 3-4.5: Fewer spins or drop
                                                            </p>
                                                            <p>
                                                                <span className="color-square bg-score-worse"></span> 4.5-5: Stay on a good trend or a sound
                                                                variety pick
                                                            </p>
                                                            <p>
                                                                <span className="color-square bg-score-middle"></span> 5-6: Stay (move up new songs at ca 5.5)
                                                            </p>
                                                            <p>
                                                                <span className="color-square bg-score-better"></span> 6-7: Slightly more spins on a steady
                                                                trend/plateau
                                                            </p>
                                                            <p className=" mb-0">
                                                                <span className="color-square bg-score-best"></span> 7-10: Significantly more spins on a steady
                                                                trend/plateau
                                                            </p>
                                                        </RATooltip>
                                                    </span>
                                                    <span className="col-1">
                                                        {sortConfig?.key === "song_score_month" &&
                                                            (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                    </span>
                                                </Stack>
                                            </th>
                                            <th className="col-1" onClick={() => requestSort("burn")}>
                                                <Stack direction="horizontal" className="justify-content-center">
                                                    <span className=" d-flex flex-wrap-reverse justify-content-center offset-1 align-items-center">
                                                        Burn
                                                        <RATooltip>
                                                            Burn measures how overplayed a song is for your audience. High burn is an indicator to play it less
                                                            often - this could be hits that need a break, or a new song that is being pushed too early.
                                                        </RATooltip>
                                                    </span>
                                                    <span className="col-1">
                                                        {sortConfig?.key === "burn" &&
                                                            (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                    </span>
                                                </Stack>
                                            </th>
                                            <th className="col-1" onClick={() => requestSort("song.release_year")}>
                                                <Stack direction="horizontal" className="justify-content-center text-center">
                                                    <span className="offset-1">Release Year</span>
                                                    <span className="col-1">
                                                        {sortConfig?.key === "song.release_year" &&
                                                            (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                    </span>
                                                </Stack>
                                            </th>
                                            <th className="text-center col-1">Trend Over Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sortedItems.length ? (
                                            sortedItems.map(item => (
                                                <tr key={item.song.item_id} className={`text-center ${selectedRow === item.song.item_id ? "active" : ""} `}>
                                                    <td className=" text-center">{item.song_category_name}</td>
                                                    <td
                                                        className={get_category_move_color(
                                                            item.song_category_name,
                                                            item.song_category_move_name,
                                                            "text-center"
                                                        )}>
                                                        {item.song_category_move_name}
                                                    </td>
                                                    <td className="text-start">{item.song.artist}</td>
                                                    <td className="text-start">{item.song.title}</td>
                                                    <td>{item.spins}</td>
                                                    <td>{item.total_spins}</td>
                                                    <td className={`text-center bold ${get_song_score_color(item.song_score)}`}>{item.song_score} </td>
                                                    <td className={`text-center bold ${get_song_score_color(item.song_score_month)}`}>
                                                        {item.song_score_month}
                                                    </td>
                                                    <td className=" text-center">{item.burn}</td>
                                                    <td className=" text-center">{item.song.release_year}</td>
                                                    <td className=" text-center py-1">
                                                        <Button className=" btn-ra-yellow py-1" size="sm" onClick={() => setSelectedRow(item.song.item_id)}>
                                                            <BarChartLineFill /> Trend
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <EmptyTableRow />
                                        )}
                                    </tbody>
                                </Table>
                            </>
                        )
                    )}
                </Col>
            </Row>
            <TrendOverTime
                show={!!selectedRow}
                onHide={() => setSelectedRow(null)}
                radioId={defaultStation ?? station[0]?.radio_id}
                itemId={selectedRow}
                dataType={1}
                daypartId={1}
            />
        </Container>
    );
};

export default PlaylistAutopilot;
