import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLoaderData, useRouteLoaderData } from "react-router-dom";
import { axiosInstance } from "../auth/Auth";
import moment from "moment/min/moment-with-locales";
import { mapPlaylistPerspective } from "./HelperFunctions";

export const playlistPerspectives = [
    { id: "w", label: "Music", color: "#396A93" },
    { id: "e", label: "Talk", color: "#99BBD6" },
    { id: "j", label: "Journalism", color: "#FEBE10" },
    { id: "n", label: "News", color: "#519872" },
    { id: "c", label: "Commercial", color: "#D5573B" },
    { id: "b", label: "Break", color: "#99BBD6" },
];

export function useSetPerspectiveOnDaysChange(setFormData, days) {
    useEffect(() => {
        setFormData(current => ({ ...current, perspective: JSON.stringify(current.days.map(x => Number(x)).sort()) }));
    }, [days, setFormData]);
}

export function useSetDaysOnPerspectiveChange(setFormData, perspective) {
    useEffect(() => {
        if (perspective && perspective !== "custom") {
            setFormData(current => ({ ...current, days: JSON.parse(perspective) }));
        }
    }, [setFormData, perspective]);
}

export function useSetCategoriesOnPlaylistPerspectiveChange(setFormData, perspectiveJson, perspectivesWithCategories) {
    useEffect(() => {
        async function setSongCategories() {
            const perspective = perspectivesWithCategories.find(x => JSON.stringify(x.categoryGroups.map(x => x.code_group)) === perspectiveJson);
            if (perspective) setFormData(current => ({ ...current, categoryGroups: perspective.categoryGroups }));
        }
        if (perspectiveJson !== "custom" && perspectivesWithCategories) setSongCategories();
    }, [setFormData, perspectivesWithCategories, perspectiveJson]);
}

export function useSetPlaylistPerspectiveOnCategoriesChange(setFormData, categoryGroups, perspectivesWithCategories) {
    useEffect(() => {
        const perspective = mapPlaylistPerspective(perspectivesWithCategories, categoryGroups);
        setFormData(current => ({
            ...current,
            playlistPerspective: JSON.stringify(perspective?.categoryGroups.map(x => x.code_group)),
        }));
    }, [categoryGroups, perspectivesWithCategories, setFormData]);
}

export function useLatestCalculatedDate(setLatestCalculatedDate, dataType, station, name) {
    useEffect(() => {
        async function getLatestCalculatedDate() {
            if (station.length && dataType) {
                const response = await axiosInstance.get(`radios/${station[0].radio_id}/latest_calculated_date?data_type=${dataType}&name=${name}`);
                setLatestCalculatedDate(response.data);
            }
        }
        getLatestCalculatedDate();
    }, [setLatestCalculatedDate, dataType, station, name]);
}

export function useLatestDate(setFormData, date, latestCalculatedDate) {
    useEffect(() => {
        if (latestCalculatedDate && (date === undefined || date > new Date(latestCalculatedDate))) {
            setFormData(current => ({
                ...current,
                date: new Date(latestCalculatedDate),
            }));
        }
    }, [date, latestCalculatedDate, setFormData]);
}

export function useSetDaypartOnChange(setFormData, station, days, hourRange) {
    const { dayparts } = useLoaderData();

    useEffect(() => {
        async function handleHourRangeChange() {
            const radioId = station[0]?.radio_id;
            const daypart = (await dayparts).data.find(
                x =>
                    x.radio_id === radioId &&
                    JSON.stringify(days.sort()) === JSON.stringify(x.weekdays) &&
                    moment(x.start_time, "HH:mm:ss").hour() === hourRange[0] &&
                    (moment(x.end_time, "HH:mm:ss").hour() + 1 || 24) === hourRange[1]
            );
            setFormData(current => ({ ...current, daypart: daypart ? [daypart] : [] }));
        }
        handleHourRangeChange();
    }, [setFormData, hourRange, dayparts, station, days]);
}

export function useSetDataTypeOnStationChange(setFormData, station) {
    useEffect(() => {
        if (station[0]?.stream && !station[0]?.ppm) {
            setFormData(current => ({ ...current, dataType: 1 }));
        } else if (!station[0]?.stream && station[0]?.ppm) {
            setFormData(current => ({ ...current, dataType: 2 }));
        }
    }, [setFormData, station]);
}

export const useOnKeyEventPress = (callback, targetKey, ctrlKey = false, shiftKey = false, elementSelector = null) => {
    useEffect(() => {
        const keyPressHandler = event => {
            if (event.target.tagName.toLowerCase() === "input") return;
            if (event.key === targetKey) {
                if (((ctrlKey && event.ctrlKey) || !ctrlKey) && ((shiftKey && event.shiftKey) || !shiftKey)) {
                    event.preventDefault();
                    callback();
                }
            }
        };
        if (elementSelector) {
            const element = document.querySelector(elementSelector);
            if (element) {
                element.addEventListener("keydown", keyPressHandler);
                return () => element.removeEventListener("keydown", keyPressHandler);
            }
        } else {
            window.addEventListener("keydown", keyPressHandler);
            return () => window.removeEventListener("keydown", keyPressHandler);
        }
    }, [callback, ctrlKey, elementSelector, shiftKey, targetKey]);
};

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
}

export function useRadioName(radioId) {
    const { radios } = useRouteLoaderData("root");
    const [radioName, setRadioName] = useState(null);

    useEffect(() => {
        async function getRadioName() {
            const foundRadio = (await radios).data.find(x => x.radio_id === radioId);
            if (foundRadio) setRadioName(foundRadio.radio_name);
        }
        if (radioId && radios) getRadioName();
    }, [radioId, radios]);

    return radioName;
}

export function useStationsAvailable(condition) {
    const { radios } = useRouteLoaderData("root");
    const [stationsAvailable, setStationsAvailable] = useState(true);

    useEffect(() => {
        async function checkStationsAvailable() {
            setStationsAvailable((await radios).data.some(condition));
        }
        checkStationsAvailable();
    }, [radios, condition]);

    return stationsAvailable;
}

export function useSongCategoriesWithPerspectives() {
    const { songCategories } = useLoaderData();
    const [perspectivesWithCategories, setPerspectivesWithCategories] = useState([]);

    useEffect(() => {
        const setCategories = async () => {
            const categories = (await songCategories).data;
            const perspectivesWithCategories = playlistPerspectives.map(perspective => ({
                ...perspective,
                categories: categories.filter(x => perspective.categoryTypes.includes(x.category_type_id)),
                categoryGroups: categories
                    .filter(x => perspective.categoryTypes.includes(x.category_type_id))
                    .filter((x, i, arr) => arr.findIndex(obj => obj.code_group === x.code_group) === i)
                    .sort((a, b) => a.category_type_id - b.category_type_id || a.code_group - b.code_group),
            }));
            setPerspectivesWithCategories(perspectivesWithCategories);
        };
        setCategories();
    }, [songCategories]);

    return perspectivesWithCategories;
}

export function useResolvedRadios(includeCompetitors = false) {
    const { radios, competitorRadios } = useRouteLoaderData("root");
    const [resolvedRadios, setResolvedRadios] = useState(null);

    useEffect(() => {
        async function resoloveRadios() {
            const resolvedRadios = includeCompetitors ? (await competitorRadios).data : (await radios).data;
            setResolvedRadios(resolvedRadios);
        }
        resoloveRadios();
    }, [competitorRadios, includeCompetitors, radios]);

    return resolvedRadios;
}

export function useResolvedRadioNames() {
    const { allRadioNames } = useRouteLoaderData("root");
    const [resolvedRadios, setResolvedRadios] = useState(null);

    useEffect(() => {
        async function resoloveRadios() {
            const resolvedRadios = (await allRadioNames).data;
            setResolvedRadios(resolvedRadios);
        }
        resoloveRadios();
    }, [allRadioNames]);

    return resolvedRadios;
}

export function useFetchData() {
    const [loading, setLoading] = useState(false);
    const [errorCode, setErrorCode] = useState(null);
    const [data, setData] = useState(null);
    const controllerRef = useRef(new AbortController());

    const fetchData = useCallback(
        async (url, inputData) => {
            setLoading(true);
            controllerRef.current.abort();
            controllerRef.current = new AbortController();
            try {
                // Line below fixes a bug when loading is not shown if fetch is fast enough.
                await new Promise(resolve => setTimeout(resolve, 0));
                const response = inputData
                    ? await axiosInstance.post(url, inputData, { signal: controllerRef.current.signal })
                    : await axiosInstance.get(url, { signal: controllerRef.current.signal });
                setData(response.data);
                setErrorCode(null);
            } catch (error) {
                setData(null);
                if (error?.code !== "ERR_CANCELED") setErrorCode(error?.response?.status ?? 500);
            }
            setLoading(false);
        },
        [setLoading, setData, setErrorCode]
    );

    return [loading, errorCode, data, fetchData, setData];
}
